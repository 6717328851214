/* eslint-disable react/no-array-index-key */
import React, {
 Fragment, Suspense, lazy, useEffect,
} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoginView from 'src/views/auth/LoginView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import screens from 'src/config/screen.routes';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { includesAny } from './constants';

export const UNPROTECTED_ROUTES = Object.freeze([
  '',
  '/',
  screens.publics.noFound,
  screens.publics.login,
  screens.publics.loginUnprotected,
  screens.publics.register,
  screens.publics.registerUnprotected,
  screens.publics.recovery,
]);

export const UNPROTECTED_ROUTES_WITH_PARAMS = Object.freeze([
  screens.publics.paymentLink, screens.publics.setAutoPaymentLink,
]);

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <LoginView />,
  },
  {
    exact: true,
    path: screens.publics.noFound,
    component: lazy(() => import('src/views/pages/Error404View')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: screens.publics.login,
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    path: screens.publics.loginUnprotected,
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: screens.publics.register,
    component: lazy(() => import('src/views/auth/RegisterView')),
  },
  {
    exact: true,
    path: screens.publics.registerUnprotected,
    component: lazy(() => import('src/views/auth/RegisterView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: screens.publics.recovery,
    component: lazy(() => import('src/views/auth/Recovery')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: `${screens.publics.paymentLink}/:paymentLink`,
    component: lazy(() => import('src/views/pages/PaymentLink')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: `${screens.publics.setAutoPaymentLink}/:paymentLink`,
    component: lazy(() => import('src/views/pages/SetAutoPaymentLink')),
  },
  {
    roleAccess: [
      'SuperUser',
      'Admin',
      'LoanServicer',
      'CustomerService',
      'AccountingDept',
    ],
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: '/app',
        component: () => <Redirect to={screens.loans.list} />, // default redirect
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.account,
        component: lazy(() => import('src/views/pages/AccountView')),
      },

      // Customers
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.customers.detail.person}/:customerId`,
        component: lazy(() => import('src/views/management/Customers/CustomerDetailsView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.customers.detail.company}/:customerId`,
        component: lazy(() => import('src/views/management/Customers/CustomerDetailsView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.customers.detail.company}/:customerId/Guarantor/:ownerId`,
        component: lazy(() => import('src/views/management/Customers/CustomerDetailsView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.customers.list,
        component: lazy(() => import('src/views/management/Customers/CustomerListView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.customers.create.person,
        component: lazy(() => import('src/views/management/Customers/CustomerCreateView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.customers.create.company,
        component: lazy(() => import('src/views/management/Customers/CustomerCreateView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.customers.importLogs,
        component: lazy(() => import('src/views/management/Customers/CustomerImportLogs')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() => import('src/views/management/Customers/CustomerDetailsView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.customers.update.person}/:customerId`,
        component: lazy(() => import('src/views/management/Customers/CustomerEditView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.customers.update.company}/:customerId`,
        component: lazy(() => import('src/views/management/Customers/CustomerEditView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.customers.update.company}/:customerId/Guarantor/:ownerId`,
        component: lazy(() => import('src/views/management/Customers/CustomerEditView/index')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.customers.update.company}/:customerId/Guarantor/Create-Guarantor`,
        component: lazy(() => import('src/views/management/Customers/CustomerEditView/index')),
      },
      // Loan Request
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.loanRequests.list,
        component: lazy(() => import('src/views/management/LoansRequest/LoansRequestListView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.loanRequests.details}/:loanRequestId`,
        component: lazy(() => import('src/views/management/LoansRequest/LoanRequestDetailsView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.loanRequests.edit}/:loanRequestId`,
        component: lazy(() => import('src/views/management/LoansRequest/LoanRequestDetailsView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.loanRequests.create,
        component: lazy(() => import('src/views/management/LoansRequest/LoanRequestEditCreateView')),
      },
      // Loans
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: screens.loans.list,
        component: lazy(() => import('src/views/management/Loans/LoanListView')),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.loans.list}/:loanId`,
        component: lazy(() => import('src/views/management/Loans/LoanDetailsView')),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.loans.list}/:loanId/:section/:activeMenu/:menuOptionActive`,
        component: lazy(() => import('src/views/management/Loans/LoanDetailsView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.loans.reschedule}/:loanId`,
        component: lazy(() => import('src/views/management/Loans/RescheduleView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.loans.autoPayment}/:loanId`,
        component: lazy(() => import('src/views/management/Loans/AutoPaymentView')),
      },

      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.loans.list}/:loanId/Loan-logs`,
        component: lazy(() => import('src/views/management/Loans/LoanLogsView')),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.loans.list}/:loanId/Pay`,
        component: lazy(() => import('src/views/management/Loans/ApplyPaymentView')),
      },
      // Cobranzas
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.debtCollection.list,
        component: lazy(() => import(
            'src/views/management/DebtCollections/DebtCollectionDashboardView'
          )),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.debtCollection.management.list,
        component: lazy(() => import('src/views/management/DebtCollections/DebtCollectionsListView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.debtCollection.factorRules.list,
        component: lazy(() => import(
            'src/views/management/DebtCollections/DebtCollectionsFactorRulesList'
          )),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.debtCollection.factorRules.create,
        component: lazy(() => import(
            'src/views/management/DebtCollections/DebtCollectionsFactorRuleNew'
          )),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.riskManagement.filters,
        component: lazy(() => import(
            'src/views/management/RiskManagementFilter/RiskManagementFilterList'
          )),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.riskManagement.create,
        component: lazy(() => import(
            'src/views/management/RiskManagementFilter/RiskManagementFilterCreate'
          )),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.riskManagement.edit}/:riskManagementFilterId`,
        component: lazy(() => import(
            'src/views/management/RiskManagementFilter/RiskManagementFilterEdit'
          )),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.debtCollection.factorRules.edit}/:factorRuleId`,
        component: lazy(() => import(
            'src/views/management/DebtCollections/DebtCollectionsFactorRuleEdit'
          )),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.debtCollection.management.detail}/:loanId/:index`,
        component: lazy(() => import(
            'src/views/management/DebtCollections/DebtCollectionManagementView'
          )),
      },
      // Reports
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.general}`,
        component: lazy(() => import('src/views/management/Reports')),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.dailyPayments}`,
        component: lazy(() => import(
            'src/views/management/Reports/ReportsManagementListView/DailyReportListView'
          )),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.delinquency}`,
        component: lazy(() => import(
            'src/views/management/Reports/ReportsManagementListView/DelinquencyReportListView'
          )),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.portfolioBalance}`,
        component: lazy(() => import(
            'src/views/management/Reports/ReportsManagementListView/PortfolioBalanceReportListView'
          )),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.loansReceivableReport}`,
        component: lazy(() => import(
            'src/views/management/Reports/ReportsManagementListView/LoansReceivableReportListView'
          )),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.loansAccountReceivableAgingReport}`,
        component: lazy(() => import(
            'src/views/management/Reports/ReportsManagementListView/LoansAccountsReceivableAgingReportListView'
          )),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.insuranceReport}`,
        component: lazy(() => import('src/views/management/Reports/InsuranceReportListView')),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.collateralReport}`,
        component: lazy(() => import('src/views/management/Reports/CollateralReportListView')),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.defaultedLoansReport}`,
        component: lazy(() => import('src/views/management/Reports/DefaultedLoansReportListView')),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.activeLoans}`,
        component: lazy(() => import('src/views/management/Reports/ActiveLoansListReportListView')),
      },

      /*
       * {
       *   roleAccess: [
       *     'SuperUser',
       *     'Admin',
       *     'LoanServicer',
       *     'CustomerService',
       *     'AccountingDept',
       *   ],
       *   exact: true,
       *   path: `${screens.reports.watchList}`,
       *   component: lazy(() => import('src/views/management/Reports/WatchListReportListView')),
       * },
       */
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.defaultedLoansReport}/:portfolioType`,
        component: lazy(() => import('src/views/management/Reports/DefaultedLoansReportListView')),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.eQLoanListReport}`,
        component: lazy(() => import('src/views/management/Reports/EqLoanListReportListView')),
      },
      {
        roleAccess: [
          'SuperUser',
          'Admin',
          'LoanServicer',
          'CustomerService',
          'AccountingDept',
        ],
        exact: true,
        path: `${screens.reports.eQLoanListReport}/:portfolioType/:availableStatus`,
        component: lazy(() => import('src/views/management/Reports/EqLoanListReportListView')),
      },
      // Tasks
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.tasks.list}`,
        component: lazy(() => import('src/views/management/Tasks/TasksListView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.tasks.details}/:taskId`,
        component: lazy(() => import('src/views/management/Tasks/TasksDetailsView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.tasks.update}/:taskId`,
        component: lazy(() => import('src/views/management/Tasks/TasksEditCreateView/')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.tasks.create}`,
        component: lazy(() => import('src/views/management/Tasks/TasksEditCreateView/')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.tasks.unassignedTasks}`,
        component: lazy(() => import('src/views/management/Tasks/UnassignedTasksListView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.management.index,
        component: () => <Redirect to={screens.customers.list} />,
      },
      // Loan Products
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.configuration,
        component: lazy(() => import('src/views/configuration/LoanProducts/LoanProductListView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.loanProducts.list,
        component: lazy(() => import('src/views/configuration/LoanProducts/LoanProductListView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: screens.loanProducts.create,
        component: lazy(() => import('src/views/configuration/LoanProducts/LoanProductCreateView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.loanProducts.details}/:loanProductId`,
        component: lazy(() => import('src/views/configuration/LoanProducts/LoanProductDetailsView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.loanProducts.edit}/:loanProductId`,
        component: lazy(() => import('src/views/configuration/LoanProducts/LoanProductCreateView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.loanProducts.details}/:loanProductId/charge-and-range/:chargeId/`,
        component: lazy(() => import(
            'src/views/configuration/LoanProducts/LoanProductDetailsView/Charges/LoanProductChargeType'
          )),
      },
      // Originators
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.originators.list}`,
        component: lazy(() => import('src/views/configuration/Originators/OriginatorsListView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.originators.create}`,
        component: lazy(() => import('src/views/configuration/Originators/OriginatorCreateView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.originators.list}/:originatorId`,
        component: lazy(() => import('src/views/configuration/Originators/OriginatorDetailsView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.originators.edit}/:originatorId`,
        component: lazy(() => import('src/views/configuration/Originators/OriginatorEditView')),
      },
      // Users
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.access.users.list}`,
        component: lazy(() => import('src/views/configuration/Users/UsersListView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.access.users.create}`,
        component: lazy(() => import('src/views/configuration/Users/UserCreateEditView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.access.users.edit}/:userId`,
        component: lazy(() => import('src/views/configuration/Users/UserCreateEditView')),
      },
      // Roles
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.access.roles}`,
        component: lazy(() => import('src/views/configuration/Roles/RolesListView')),
      },
      // Funders
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.funders.list}`,
        component: lazy(() => import('src/views/configuration/Funders/FundersListView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.funders.create}`,
        component: lazy(() => import('src/views/configuration/Funders/FundersCreateView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.organization.settings}`,
        component: lazy(() => import('src/views/configuration/Integrations/SettingsView')),
      },
      // Campaigns
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.campaigns.list}`,
        component: lazy(() => import('src/views/management/Campaigns/CampaignsListView')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.campaigns.details}/:campaignId`,
        component: lazy(() => import('src/views/management/Campaigns/CampaignsDetailsView/index')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.campaigns.create}`,
        component: lazy(() => import('src/views/management/Campaigns/CampaignsCreateEditView/index')),
      },
      {
        roleAccess: ['SuperUser',
'Admin',
'LoanServicer',
'CustomerService'],
        exact: true,
        path: `${screens.campaigns.edit}/:campaignId`,
        component: lazy(() => import('src/views/management/Campaigns/CampaignsCreateEditView/index')),
      },

      // Accounting
      {
        roleAccess: ['SuperUser',
'Admin',
'AccountingDept'],
        exact: true,
        path: screens.accounting.dashboard,
        component: lazy(() => import('src/views/accounting/Dashboard/')),
      },
      // Admin
      {
        roleAccess: ['SuperUser', 'Admin'],
        exact: true,
        path: screens.management.dashboard,
        component: lazy(() => import('src/views/management/Dashboard')),
      },
      // Fin
      {
        component: () => <Redirect to={screens.publics.noFound} />,
      },
    ],
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView,
      },
      {
        component: () => <Redirect to={screens.publics.noFound} />,
      },
    ],
  },
];

const renderRoutes = (routes, roles) => {
  if (!routes) {
    return null;
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          const hasAccess = route.roleAccess ? includesAny(route.roleAccess, roles) : false;

          if (route.roleAccess !== undefined && !hasAccess) {
            <div />;
          } else {
            return (
              <Route
                key={i}
                exact={route.exact}
                path={route.path}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.routes ? renderRoutes(route.routes, roles) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          }
        })}
      </Switch>
    </Suspense>
  );
};

function Routes() {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.account);

  const { message, variant, id } = useSelector((state) => state.ui.msgSnackbar);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (message !== '') {
      enqueueSnackbar(t(message), { variant });
    }
  }, [id]);

  return renderRoutes(routesConfig, user?.roles ?? []);
}

export default Routes;
