export default {
  configuration: '/app/configuration',
  management: {
    index: '/app/management',
    dashboard: '/app/management/dashboard',
  },
  publics: {
    noFound: '/404',
    login: '/login',
    loginUnprotected: '/login-unprotected',
    register: '/register',
    registerUnprotected: '/register-unprotected',
    recovery: '/recovery',
    paymentLink: '/payment-link',
    setAutoPaymentLink: '/set-auto-payment',
  },
  customers: {
    list: '/app/management/customers',
    create: {
      person: '/app/management/customers/create/person',
      company: '/app/management/customers/create/company',
    },
    update: {
      person: '/app/management/customers/update/person',
      company: '/app/management/customers/update/company',
    },
    detail: {
      person: '/app/management/customers/detail/person',
      company: '/app/management/customers/detail/company',
    },
    importLogs: '/app/management/customers/import-logs',
  },
  loanRequests: {
    list: '/app/management/loan-requests',
    details: '/app/management/loan-requests/details',
    edit: '/app/management/loan-requests/edit',
    create: '/app/management/loan-request/create',
  },
  loans: {
    list: '/app/management/loans',
    reschedule: '/app/management/loans/reschedule',
    autoPayment: '/app/management/loans/autoPayment',
  },
  tasks: {
    list: '/app/management/tasks',
    create: '/app/management/tasks/create',
    update: '/app/management/tasks/update',
    details: '/app/management/tasks/details',
    unassignedTasks: '/app/management/tasks/unassigned',
  },
  debtCollection: {
    list: '/app/management/debt-collection',
    management: {
      list: '/app/management/debt-collection/customers',
      detail: '/app/management/debt-collection/customers/detail',
    },
    factorRules: {
      list: '/app/management/debt-collection/factor-rules',
      create: '/app/management/debt-collection/factor-rules/create',
      edit: '/app/management/debt-collection/factor-rules/edit',
    },
  },
  riskManagement: {
    reports: '/app/management/risk-management/reports',
    create: '/app/management/risk-management/create',
    edit: '/app/management/risk-management/edit',
    filters: '/app/management/risk-management/filters',
  },
  campaigns: {
    list: '/app/management/campaigns',
    edit: '/app/management/campaigns/edit',
    create: '/app/management/campaigns/create',
    details: '/app/management/campaigns/details',
  },
  reports: {
    list: '/app/management/reports',
    loansAccountReceivableAgingReport:
      '/app/management/reports/accounts-receivable-aging',
    general: '/app/management/reports/general',
    dailyPayments: '/app/management/reports/daily-payments',
    delinquency: '/app/management/reports/delinquency',
    loansReceivableReport: '/app/management/reports/loans-receivable',
    portfolioBalance: '/app/management/reports/portfolio-balance',
    insuranceReport: '/app/management/reports/insurance',
    collateralReport: '/app/management/reports/collateral-report',
    defaultedLoansReport: '/app/management/reports/defaulted-loans',
    eQLoanListReport: '/app/management/reports/eq-loan-list',
    activeLoans: '/app/management/reports/active-loans',
    // watchList: '/app/management/reports/watch-list',
  },
  loanProducts: {
    list: '/app/configuration/loan-products',
    create: '/app/configuration/loan-products/create',
    edit: '/app/configuration/loan-products/edit',
    details: '/app/configuration/loan-products/details',
  },
  organization: {
    settings: '/app/configuration/organization/settings',
  },
  access: {
    users: {
      list: '/app/configuration/users',
      edit: '/app/configuration/users/edit',
      create: '/app/configuration/users/create',
    },
    roles: '/app/configuration/roles',
  },
  funders: {
    list: '/app/configuration/funders',
    create: '/app/configuration/funders/create',
  },
  originators: {
    list: '/app/configuration/originators',
    create: '/app/configuration/originators/create',
    edit: '/app/configuration/originators/edit',
  },
  account: '/app/account',
  accounting: {
    dashboard: '/app/accounting/dashboard',
  },
};
